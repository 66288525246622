<template>
    <el-form ref="formData" :model="formData" :rules="rules" label-position="top" class="flex">
        <el-form-item :label="$t('calendar.start_time')" prop="startTime" class="mr-4">
            <el-input v-model="formData.startTime" v-mask="'##:##'" suffix-icon="el-icon-alarm-clock" @blur="startTimeBlur" />
        </el-form-item>
        <el-form-item :label="$t('calendar.end_time')" prop="endTime">
            <el-input v-model="formData.endTime" v-mask="'##:##'" suffix-icon="el-icon-alarm-clock" @blur="endTimeBlur" />
        </el-form-item>
    </el-form>
</template>
<script>
import Vue from 'vue';

import VueTheMask from 'vue-the-mask';
import {
    verifyStartTime, verifyEndTime,
} from '../../../ValidationHelper';

Vue.use(VueTheMask);

export default {
    props: {
        startTime: {
            type:    String,
            default: '',
        },
        endTime: {
            type:    String,
            default: '',
        },
    },

    data() {
        const timeStartValidator = (rule, value, callback) => verifyStartTime(value, this.formData.startTime, this.formData.endTime, callback, this.$dayjs);
        const timeEndValidator = (rule, value, callback) => verifyEndTime(value, this.formData.startTime, this.formData.endTime, callback, this.$dayjs);

        return {
            formData: {
                startTime: '07:00',
                endTime:   '08:00',
            },
            rules: {
                startTime: [{ validator: timeStartValidator, trigger: 'blur' }],
                endTime:   [{ validator: timeEndValidator, trigger: 'blur' }],
            },
        };
    },

    watch: {
        startTime() {
            this.formData.startTime = this.startTime;
        },
        endTime() {
            this.formData.endTime = this.endTime;
        },
    },

    methods: {
        startTimeBlur() {
            const startHours = this.formData.startTime.substring(0, 2);
            const endHours = this.formData.endTime.substring(0, 2);
            const startMinutes = this.formData.startTime.substring(3, 5);
            const endMinutes = this.formData.endTime.substring(3, 5);
            const startMoment = this.$dayjs().hour(startHours).minute(startMinutes);
            const endMoment = this.$dayjs().hour(endHours).minute(endMinutes);

            if (startMoment >= endMoment) {
                this.formData.endTime = startMoment.add(1, 'hour').format('HH:mm');
            }
            this.formChanged();
        },

        endTimeBlur() {
            const startHours = this.formData.startTime.substring(0, 2);
            const endHours = this.formData.endTime.substring(0, 2);
            const startMinutes = this.formData.startTime.substring(3, 5);
            const endMinutes = this.formData.endTime.substring(3, 5);
            const startMoment = this.$dayjs().hour(startHours).minute(startMinutes);
            const endMoment = this.$dayjs().hour(endHours).minute(endMinutes);

            if (endMoment <= startMoment) {
                this.formData.startTime = endMoment.subtract(1, 'hour').format('HH:mm');
            }
            this.formChanged();
        },

        formChanged() {
            this.$emit('startTimeChanged', this.formData.startTime);
            this.$emit('endTimeChanged', this.formData.endTime);
        },
    },
};
</script>
